<template>
  <b-card>
    <b-row>
      <vue-confirm-dialog />
      <b-col cols="12">
        <b-card-body>
          <b-row class="mb-1">
            <b-col md="9">
              <h2>
                Fornecedores
              </h2>
            </b-col>
            <b-col>
              <b-button
                block
                variant="gradient-success"
                @click="adicionar()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Adicionar fornecedor
              </b-button>
            </b-col>
          </b-row>
          <data-table
            :data="data"
            :columns="columns"
            @on-table-props-changed="reloadTable"
          >
            <tbody
              slot="body"
            >
              <tr
                v-for="item in data.data"
                :key="item.id"
              >
                <td
                  v-for="column in columns"
                  :key="column.name"
                >
                  <data-table-cell
                    :value="item"
                    :name="column.name"
                    :meta="column.meta"
                    :comp="column.component"
                    :classes="column.classes"
                  />
                  <slot
                    v-if="column.label === 'Ações'"
                  >
                    <b-button
                      variant="info"
                      class="btn-icon rounded-circle"
                      @click="EditarProjeto(item)"
                    >
                      <feather-icon icon="Edit3Icon" />
                    </b-button>
                    <b-button
                      variant="danger"
                      class="btn-icon rounded-circle"
                      @click="DeletarDados(item)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </slot>
                  <slot v-if="column.label === 'status'">
                    <b-badge :variant="NomeStatus(item).cor">
                      {{ NomeStatus(item).msg }}
                    </b-badge>
                  </slot>
                </td>
              </tr>
            </tbody>
          </data-table>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      url: '/suppliers/getall',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'name',
          orderable: true,
        },
        {
          label: 'e-mail',
          name: 'email',
          orderable: true,
        },
        {
          label: 'estado',
          name: 'state',
          orderable: true,
        },
        {
          label: 'cidade',
          name: 'city',
          orderable: true,
        },
        {
          label: 'status',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
    }
  },
  created() {
    this.BuscarDados(this.url)
  },
  methods: {
    async BuscarDados(url = this.url, options = this.tableProps) {
      const { data } = await this.$http.get(url, { params: options })
      this.data = data
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    adicionar() {
      this.$router.push('/fornecedor/adicionar')
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'secondary', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'success', msg: 'Ativo' }
      }
      return { cor: 'danger', msg: 'Sem status' }
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    EditarProjeto(item) {
      localStorage.setItem('fornecedor/editar', JSON.stringify(item))
      this.$router.push({
        name: 'app-supplier-edit',
        params: {
          id: item.id,
        },
      })
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              const { data } = await this.$http.post('/suppliers/delete', item)
              if (data.success) {
                this.Notificacao('success', 'Fornecedor Removido com sucesso')
                this.reloadTable(this.tableProps)
              } else {
                this.Notificacao('danger', 'Erro')
              }
            }
          },
        },
      )
    },
  },
}

</script>

<style scoped>
</style>
